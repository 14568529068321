function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div>
            <div className="footer-logo">PAYSM</div>
            <p></p>
            <div className="footer-address">
              <p>대구광역시 중구 동덕로 115, 1115호</p>
              <p>제휴문의: manager@skyclassism.com</p>
              <p>고객센터: 053-267-0880</p>
              <p>팩스: 0504-443-6240</p>
            </div>
          </div>

          <div className="footer-links">
            <ul className="footer-menu">
              <li>
                <a href="https://paysm.net">회사소개</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2023 SKYCLASS. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

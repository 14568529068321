function Approval() {
    const codeString = `
  var encData = $('input[name="encData"]').val();
  console.log(encData); // 콘솔에 값 출력
  
  window.addEventListener('message', function (event) {
      // 메시지 처리
      var data = event.data;
      console.log("Received data:", data);
      let receiveData = data[1]
  
      $.ajax({
          url: 'https://api.skyclassism.com/payment.do',
          type: 'POST',
          data: {
              tid: receiveData.tid,
              ediDate: receiveData.ediDate,
              mid: receiveData.mid,
              goodsAmt: receiveData.goodsAmt,
              charSet: receiveData.charSet,
              encData: encData,
              signData: receiveData.signData
          },
          success: function (response) {  
              let response_json = JSON.parse(response)
              // AJAX 요청 성공 후 실행할 코드
              var form = $('<form></form>');
              form.attr('method', 'post');
              form.attr('action', 'https://testapi.com/result');  //승인결과 수신 URL
              for (let key in response_json) {
                  console.log(key + ' : ' + response_json[key])
                  // 필요한 데이터를 form에 추가합니다
                  var field = $('<input></input>');
                  field.attr('type', 'hidden');
                  field.attr('name', key);
                  field.attr('value', response_json[key]); 
                  form.append(field);
              }
              // form을 body에 추가하고 제출합니다
              $(document.body).append(form);
              form.submit();
          },
          error: function (xhr, status, error) {
              console.error("Error occurred: ", error);
          }
      });
  });
    `;
  
    return (
      <pre><code>{codeString}</code></pre>
    );

    
  }
  export default Approval;
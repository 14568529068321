import Logo from "../logo.png";

function SideBar() {
  return (
    <div id="side-bar" className="bg-body-tertiary">
      <div className="bar-title">
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="SKYCLASS" width="130" height="40" />
        </a>
      </div>
      <div className="flex-shrink-0 p-3">
        <ul className="list-unstyled ps-0">
          <div className="scrollable-menu">
            <li className="mb-1">
              <button
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#link-api"
                aria-expanded="true"
              >
                API 연동
              </button>
              <div className="collapse show" id="link-api">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <a
                      href="#link-env"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      연동환경
                    </a>
                  </li>
                  <li>
                    <a
                      href="#link-api-url"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      API URL 주소
                    </a>
                  </li>
                  <li>
                    <a
                      href="#link-way"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      연동방법
                    </a>
                  </li>
                  <li>
                    <a
                      href="#link-info"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      연동정보
                    </a>
                  </li>

                </ul>
              </div>
            </li>
            <li className="border-top my-3"></li>
            <li className="mb-1">
              <button
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#quick-start"
                aria-expanded="true"
              >
                빠른시작
              </button>
              <div className="collapse show" id="quick-start">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <a
                      href="#quick-test"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      테스트
                    </a>
                  </li>

                </ul>
              </div>
            </li>
            <li className="border-top my-3"></li>
            <li className="mb-1">
              <button
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#request-payment"
                aria-expanded="true"
              >
                결제요청
              </button>
              <div className="collapse show" id="request-payment">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <a
                      href="#link-auth-req"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      인증요청
                    </a>
                  </li>
                  <li>
                    <a
                      href="#link-auth-res"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      인증결과수신
                    </a>
                  </li>

                  <li>
                    <a
                     href="#link-req-approval"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      승인요청
                    </a>
                  </li>
                  <li>
                    <a
                     href="#link-res-approval"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      승인결과수신
                    </a>
                  </li>

                  <li>
                    <a
                     href="#link-example-approval"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      승인코드샘플
                    </a>
                  </li>

                </ul>
              </div>
            </li>



            <li className="border-top my-3"></li>
            <li className="mb-1">
              <button
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#request-cancel"
                aria-expanded="true"
              >
                결제취소
              </button>
              <div className="collapse show" id="request-cancel">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <a
                      href="#link-cancel-req"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      취소요청
                    </a>
                  </li>


                </ul>
              </div>
            </li>
            {/*<li className="border-top my-3"></li>
            <li className="mb-1">
              <button
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#noti-payment"
                aria-expanded="false"
              >
                결제통보
              </button>
              <div className="collapse" id="noti-payment">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <a
                      href="1"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      테스트
                    </a>
                  </li>

                </ul>
              </div>
            </li>*/}

            <li className="border-top my-3"></li>
            
            <li className="mb-1">
              <button
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#receipt"
                aria-expanded="true"
              >
                영수증
              </button>
              <div className="collapse show" id="receipt">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <a
                      href="#creditcard-receipt"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      신용카드
                    </a>
                  </li>


                </ul>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;

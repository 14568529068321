import Main from "./component/Main";
import SideBar from "./component/SideBar";

function App() {
  return (
    <div className="wrapper">
      

       <div className="container">

          <SideBar></SideBar>
          <Main></Main>
      </div>
    </div>
  );
}

export default App;

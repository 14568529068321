import React, { useEffect, useRef, useState } from "react";

import Approval from "./codedisplay/Approval";
import { getEdiDate } from "../util/date";
import { getEncData } from "../util/payment";

import Modal from 'bootstrap/js/dist/modal';
import Footer from "./Footer";



function Main() {
  const [mid1, setMid1] = useState("paytest01m");
  const [mid2, setMid2] = useState("paytest02m");

  const [goodsAmt, setGoodsAmt] = useState("1000");
  const [ediDate, setEdiDate] = useState(getEdiDate(new Date()));
  const [encData, setEncData] = useState("");

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      const targetId = this.getAttribute("href");
      const targetElement = document.querySelector(targetId);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    });
  });

  const modalRef = useRef(null);

  useEffect(() => {
    document.getElementById('postForm').addEventListener('submit', function () {
      //$('#responseModal').modal('show');
      //let myModal = new bootstrap.Modal(document.getElementById('responseModal'), {})

          // 모달 인스턴스 생성
    let myModal = new Modal(modalRef.current, {
      // 옵션 설정 (필요한 경우)
    });

    // 모달을 여는 등의 추가적인 작업
    myModal.show();
    });

    getEncData(mid1 + ediDate + goodsAmt + mKey1).then((hash) => {
      setEncData(hash);
    });




    window.addEventListener('message', function (event) {
      // 메시지 처리
      var data = event.data;
      let receiveData = data[1]

      console.log('data : ' + JSON.stringify(data))
      /*
      $.ajax({
          url: 'https://api.skyclassism.com/payment.do',
          type: 'POST',
          data: {
              tid: receiveData.tid,
              ediDate: receiveData.ediDate,
              mid: receiveData.mid,
              goodsAmt: receiveData.goodsAmt,
              charSet: receiveData.charSet,
              encData: encData,
              signData: receiveData.signData
          },
          success: function (response) {  
              let response_json = JSON.parse(response)
              // AJAX 요청 성공 후 실행할 코드
              var form = $('<form></form>');
              form.attr('method', 'post');
              form.attr('action', 'https://testapi.com/result');  //승인결과 수신 URL
              for (let key in response_json) {
                  console.log(key + ' : ' + response_json[key])
                  // 필요한 데이터를 form에 추가합니다
                  var field = $('<input></input>');
                  field.attr('type', 'hidden');
                  field.attr('name', key);
                  field.attr('value', response_json[key]); 
                  form.append(field);
              }
              // form을 body에 추가하고 제출합니다
              $(document.body).append(form);
              form.submit();
          },
          error: function (xhr, status, error) {
              console.error("Error occurred: ", error);
          }
      }); 
*/

      if (receiveData) {

        let encData1 = document.querySelector('input[name="encData"]').value;

        let formData = new FormData();
        formData.append("tid", receiveData.tid)
        formData.append("ediDate", receiveData.ediDate)
        formData.append("mid", receiveData.mid)
        formData.append("goodsAmt", receiveData.goodsAmt)
        formData.append("charSet", receiveData.charSet)
        formData.append("encData", encData1)
        formData.append("signData", receiveData.signData)

        console.log('init encData : ' + encData1)
  
        fetch('https://api.skyclassism.com/payment.do', {
          method: 'POST',
          body: formData
        }).then(response => {
          response.json().then(data => {
            if (data.resultCd === '3001') {
              alert('결제가 완료되었습니다!')
              alert('현재 tid : ' + data.tid)
            } else {
              //alert('이미 중복된 거래입니다.')
            }
          })
        })
      }
  });


  }, []);

  useEffect(() => {
    console.log('mid 변경')
    if (mid1 === 'paytest02m') {
      getEncData(mid2 + ediDate + goodsAmt + mKey2).then((hash) => {
        setEncData(hash);
      });
    } else {
      getEncData(mid1 + ediDate + goodsAmt + mKey1).then((hash) => {
        setEncData(hash);
      });
    }
  }, [mid1]);


  let mKey1 =
    "TNhT5uqthAYek05Y2L5FT4t8azHY5fjuCWyMsWkVknSyomV3NxuTfw4vG0yaAxe+BzivMdLGkRDMS6XLUAL5nA==";
  let mKey2 =
    "6JiFjc83k+Sx/HsTS39uzQWP4+ARsfjQGMpCWwwePHLaHoVd3zJQlBSSuuILgiMusPhTTiKIqkHqHQuQMRzYiA==";


  let modalStyle1 = {
    padding: "0",
  };

  let modalStyle2 = {
    width: "100%",
    height: "660px",
    overflow: "auto",
  };




  

  return (
    <main className="main">
      <div className="scrollable-area">
        <div className="title">
          <h4>Paysm 서비스 연동 메뉴얼 입니다.</h4>
        </div>
        <p className="border-top top-line"></p>
        <div className="p-3">
          <ul>
            <li>
              MMS 가맹점 관리 사이트의 가맹점정보 - 결제환경 설정 - KEY 관리에서
              상점키를 확인하실 수 있습니다.{" "}
            </li>
            <li>
              샘플 파일은 결제연동을 위한 기본적인 사항만 기재되어 있으므로 실제
              개발 시에는 샘플 페이지를 참고하여 고객사의 환경에 맞게 연동
              하시기 바랍니다.
            </li>
            <li className="bold">
              연동 매뉴얼에 설명하는 API의 값을 임의로 변조,변형 하신 경우
              결제가 원활하게 이뤄지지 않을 수 있으며, 최신 업데이트가 적용되지
              않기 때문에 동작을 보장할 수 없습니다.
            </li>
            <li>
              결제 샘플 코드는 참조용으로 결제 연동 개발에 대한 모든 책임은
              가맹점에 있습니다.
            </li>
          </ul>
        </div>
        <p className="border-top top-line"></p>

        <div className="title">
          <h5>연동 API 구성방법.</h5>
        </div>
        <p className="border-top top-line"></p>
        <div className="p-3" id="link-env">
          <p className="sub-title">연동 환경</p>
          <ul>
            <li>
              자체개발된 서버사이드 환경이라면 Paysm API 연동이 가능합니다.{" "}
            </li>
            <li className="small-bold">
              현재 준비되어진 샘플파일은 다음과 같습니다.
              <ul className="sub-list">
                <li>
                  <a href="https://bitbucket.org/skyclass/paysm-sample-php/src/main/">
                    PHP
                  </a>
                </li>
                <li>
                  <a href="https://bitbucket.org/skyclass/paysm-sample-jsp/src/main/">
                    JSP
                  </a>
                </li>
                <li>
                  <a href="https://bitbucket.org/skyclass/paysm-sample-springboot/src/main/">
                    스프링부트
                  </a>
                </li>
                <li>
                  <a href="https://bitbucket.org/skyclass/nonui/src/main/">
                    자사 결제페이지를 사용하는 경우
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <p className="border-top top-line"></p>
        <div className="p-3" id="link-api-url">
          <p className="sub-title">API 주소</p>
          <ul>
            <li>
              <a href="https://api.skyclassism.com">
                https://api.skyclassism.com
              </a>
            </li>
          </ul>
        </div>

        <p className="border-top top-line"></p>
        <div className="p-3" id="link-way">
          <p className="sub-title">연동 방법</p>
          <ul>
            <li> API 페이지를 호출해서 응답 값을 받아 연동합니다.</li>
            <li className="small-bold">
              API URL 호출의 진입지점은 FORM 요청으로 시작되어야 합니다. (Client
              Side 호출){" "}
            </li>
          </ul>
        </div>

        <p className="border-top top-line"></p>
        <div className="p-3" id="link-info">
          <p className="sub-title">연동 정보</p>
          <ul>
            <li>
              가맹점 MID 발급이후{" "}
              <a href="https://mms.skyclassism.com">가맹점 관리 사이트</a>의
              가맹점 정보 - 결제환경 설정 - KEY 관리에서 상점키가 있어야 연동이
              가능합니다.
            </li>
            <li className="small-bold">
              API URL 호출의 진입지점은 FORM 요청으로 시작되어야 합니다. (Client
              Side 호출){" "}
            </li>
            <li>
              연동 정보 값은 가맹점마다 값이 다르며 영문 대/소문자, 숫자, 기호로
              구성되어 있습니다.
            </li>
            <li className="small-bold">
              연동 정보 값은 보안상의 이유로 외부로 유출이 되지 않도록 주의
              하시기 바랍니다.
            </li>

            <li className="small-bold">
              <p>해당 테스트용 MID와 상점키로 결제테스트 하실수 있습니다.</p>
              <ul>
                <li>
                  카드인증결제 MID : &nbsp; <span className="data">{mid1}</span>
                </li>
                <li>
                  카드인증결제 상점키 : &nbsp;{" "}
                  <span className="data2">{mKey1}</span>
                </li>

                <li className="mt-3">
                  카드수기결제 MID : &nbsp; <span className="data">{mid2}</span>
                </li>
                <li>
                  카드수기결제 상점키 : &nbsp;{" "}
                  <span className="data2">{mKey2}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <p className="border-top top-line"></p>
        <div className="p-3" id="quick-test">
          <p className="sub-title quick-start-title">빠른시작</p>
          <ul>
            <li className="small-bold">
              고객사의 프로그램에 맞게 수정 하셔서 사용하셔야 합니다.
            </li>
            <li className="small-bold">
              매뉴얼의 예제 프로그램으로 작성되었습니다.
            </li>
          </ul>

          <div className="card">
            <div className="card-header d-flex justify-content-center pt-3">
              <h5>연동예제 테스트</h5>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-center mt-4">
                <span className="small-bold">
                  ---------------------------------------- &nbsp;
                </span>
                <span className="small-bold">필수사항</span>
                <span className="small-bold">
                  &nbsp; ----------------------------------------
                </span>
              </div>

              <form
                method="post"
                action="https://api.skyclassism.com/payInit_hash.do"
                target="responseIframe"
                id="postForm"
              >
                <div className="form-group">
                  <label htmlFor="payMethod">결제수단</label>
                  <input
                    className="form-control"
                    type="text"
                    id="payMethod"
                    name="payMethod"
                    defaultValue={"card"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="mid">MID</label>
                  <input
                    className="form-control"
                    type="text"
                    id="mid"
                    name="mid"
                    defaultValue={mid1}
                    onChange={(event) => {
                      setMid1(event.target.value);       
                    }}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="goodsNm">상품명</label>
                  <input
                    className="form-control"
                    type="text"
                    id="goodsNm"
                    name="goodsNm"
                    defaultValue={"테스트 상품"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="ordNo">주문번호</label>
                  <input
                    className="form-control"
                    type="text"
                    id="ordNo"
                    name="ordNo"
                    defaultValue={"123123123"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="ordNm">구매자명</label>
                  <input
                    className="form-control"
                    type="text"
                    id="ordNm"
                    name="ordNm"
                    defaultValue={"홍길동"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="ordTel">구매자연락처</label>
                  <input
                    className="form-control"
                    type="text"
                    id="ordTel"
                    name="ordTel"
                    defaultValue={"01012341234"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="goodsAmt">결제금액</label>
                  <input
                    className="form-control"
                    type="text"
                    id="goodsAmt"
                    name="goodsAmt"
                    defaultValue={goodsAmt}
                    onChange={(event) => {
                      setGoodsAmt(event.target.value);
                      getEncData(mid1 + ediDate + goodsAmt + mKey1).then(
                        (hash) => setEncData(hash)
                      );
                    }}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="trxCd">에스크로 여부</label>
                  <input
                    className="form-control"
                    type="text"
                    id="trxCd"
                    name="trxCd"
                    defaultValue={"0"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="ediDate">주문날짜 타임스탬프</label>
                  <input
                    className="form-control"
                    type="text"
                    id="ediDate"
                    name="ediDate"
                    defaultValue={ediDate}
                    onChange={(event) => {
                      setEdiDate(event.target.value);
                      getEncData(mid1 + ediDate + goodsAmt + mKey1).then(
                        (hash) => setEncData(hash)
                      );
                    }}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="encData">위변조 방지데이터</label>
                  <input
                    className="form-control"
                    type="text"
                    id="encData"
                    name="encData"
                    defaultValue={encData}
                  ></input>
                </div>

                <div className="d-flex justify-content-center mt-5">
                  <span className="small-bold">
                    ---------------------------------------- &nbsp;
                  </span>
                  <span className="small-bold">선택사항</span>
                  <span className="small-bold">
                    &nbsp; ----------------------------------------
                  </span>
                </div>

                <div className="form-group">
                  <label htmlFor="ordEmail">구매자이메일</label>
                  <input
                    className="form-control"
                    type="text"
                    id="ordEmail"
                    name="ordEmail"
                    defaultValue={"testmail@naver.com"}
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="notiUrl">알림URL</label>
                  <input
                    className="form-control"
                    type="text"
                    id="notiUrl"
                    name="notiUrl"
                    defaultValue={"https://example.com/noti"}
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="userIp">구매자 IP</label>
                  <input
                    className="form-control"
                    type="text"
                    id="userIp"
                    name="userIp"
                    defaultValue={"127.0.0.1"}
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="returnUrl">RETURN URL (Mobile)</label>
                  <input
                    className="form-control"
                    type="text"
                    id="returnUrl"
                    name="returnUrl"
                    defaultValue={"https://mobile.example.com/receive"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="mbsReserved">상점 예약</label>
                  <input
                    className="form-control"
                    type="text"
                    id="mbsReserved"
                    name="mbsReserved"
                    defaultValue={""}
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="goodsSplAmt">공급가</label>
                  <input
                    className="form-control"
                    type="text"
                    id="goodsSplAmt"
                    name="goodsSplAmt"
                    defaultValue={"100"}
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="goodsVat">부가세</label>
                  <input
                    className="form-control"
                    type="text"
                    id="goodsVat"
                    name="goodsVat"
                    defaultValue={"100"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="goodsSvsAmt">봉사료</label>
                  <input
                    className="form-control"
                    type="text"
                    id="goodsSvsAmt"
                    name="goodsSvsAmt"
                    defaultValue={"100"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="channel">접속타입</label>
                  <input
                    className="form-control"
                    type="text"
                    id="channel"
                    name="channel"
                    defaultValue={"0001"}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="period">제공기간</label>
                  <input
                    className="form-control"
                    type="text"
                    id="period"
                    name="period"
                    defaultValue={""}
                  ></input>
                </div>

                <div className="form-group">
                  <label htmlFor="charSet">인코딩방식</label>
                  <input
                    className="form-control"
                    type="text"
                    id="charSet"
                    name="charSet"
                    defaultValue={"UTF-8"}
                  ></input>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary w-25"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <p className="border-top top-line mt-5"></p>
        <div className="p-3">
          <p className="sub-title" id="link-auth-req">
            인증요청
          </p>
          
          <ul>
            <li className="small-bold mb-5">
              <p>결제순서는 다음과 같습니다.</p>
              <ol>
                <li>결제 인증요청</li>
                <li>인증결과데이터 수신</li>
                <li>수신된 인증데이터로 승인요청</li>
                <li>최종 승인이후 사용자 페이지로 리다이렉트</li>
              </ol>
            </li>
            <li className="small-bold">
              ediDate 와 encData 는 가맹점에서 직접 생성하여야 합니다.
            </li>
            <li className="small-bold">
              encData 생성시 ediDate 가 먼저 생성되어있어야 합니다.
            </li>
            <li className="small-bold">
              returnURL 은 모바일에서만 동작합니다. PC웹에서는 message 이벤트
              수신으로 인증결과 수신
            </li>
            <ul>
              <li className="small-bold">
                ediDate : 현재날짜시각기준 "yyyyMMddHHmmss" 포맷형식 문자열
              </li>
              <li className="small-bold">
                encData : "mid + ediDate + 상품가격 + 상점키" 문자열을 합친후
                SHA256 으로 해싱
              </li>
            </ul>
          </ul>


          <table className="table">
            <thead>
              <tr>
                <th scope="col">URL</th>
                <th scope="col">Method</th>
                <th scope="col">Content-Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">https://api.skyclassism.com/payInit_hash.do</td>
                <td className="small-bold">POST</td>
                <td className="small-bold">application/x-www-form-urlencoded; charset=utf-8</td>
              </tr>
            </tbody>
          </table>


          <div className="p-3 small-bold">※ 필수</div>
                    
          <table className="table">
            <thead>
              <tr>
                <th scope="col">name</th>
                <th scope="col">value</th>
                <th scope="col">description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">payMethod</td>
                <td>card</td>
                <td>결제수단 ( "card" ) </td>
              </tr>
              <tr>
                <td className="small-bold">mid</td>
                <td>paytest01m</td>
                <td>발급받은 가맹점 mid</td>
              </tr>

              <tr>
                <td className="small-bold">goodsNm</td>
                <td>테스트 상품</td>
                <td>상품명</td>
              </tr>

              <tr>
                <td className="small-bold">ordNm</td>
                <td>홍길동</td>
                <td>구매자명</td>
              </tr>

              <tr>
                <td className="small-bold">ordTel</td>
                <td>01012345678</td>
                <td>구매자연락처</td>
              </tr>

              <tr>
                <td className="small-bold">goodsAmt</td>
                <td>1000</td>
                <td>결제금액</td>
              </tr>

              <tr>
                <td className="small-bold">trxCd</td>
                <td>0</td>
                <td>에스크로 여부 ( 0 : 고정 )</td>
              </tr>

              <tr>
                <td className="small-bold">ediDate</td>
                <td>20240126103040</td>
                <td>현재날짜시각기준 "yyyyMMddHHmmss" 문자열</td>
              </tr>

              <tr>
                <td className="small-bold">encData</td>
                <td>qwdq12e54jkndqw.....</td>
                <td>SHA256 (mid + ediDate + goodsAmt + 상점키)</td>
              </tr>
            </tbody>
          </table>

          <div className="p-3 small-bold">※ 선택</div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">name</th>
                <th scope="col">value</th>
                <th scope="col">description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">mbsReserved</td>
                <td></td>
                <td>상점예약 문자열 (주문번호 등을 입력)</td>
              </tr>

              <tr>
                <td className="small-bold">ordEmail</td>
                <td>testmail@naver.com</td>
                <td>구매자이메일</td>
              </tr>

              <tr>
                <td className="small-bold">notiUrl</td>
                <td>https://example.com/noti</td>
                <td>알림 URL</td>
              </tr>

              <tr>
                <td className="small-bold">userIp</td>
                <td>127.0.0.1</td>
                <td>구매자 IP</td>
              </tr>

              <tr>
                <td className="small-bold">returnUrl</td>
                <td>https://mobile.example.com/receive</td>
                <td>모바일에서 이동되어질 returnURL</td>
              </tr>

              <tr>
                <td className="small-bold">goodsSplAmt</td>
                <td>100</td>
                <td>공급가 (대상 : '전송금액기준' 설정업체에 한함)</td>
              </tr>

              <tr>
                <td className="small-bold">goodsVat</td>
                <td>100</td>
                <td>부가세 (대상 : '전송금액기준' 설정업체에 한함)</td>
              </tr>

              <tr>
                <td className="small-bold">goodsSvsAmt</td>
                <td>100</td>
                <td>봉사료 (대상 : '전송금액기준' 설정업체에 한함)</td>
              </tr>

              <tr>
                <td className="small-bold">channel</td>
                <td>0001</td>
                <td>접속구분 (0001:PC, 0002:mobile)</td>
              </tr>

              <tr>
                <td className="small-bold">period</td>
                <td></td>
                <td>제공기간 (default : 별도 제공기간 없음)</td>
              </tr>

              <tr>
                <td className="small-bold">charSet</td>
                <td>UTF-8</td>
                <td>인코딩방식 ( UTF-8 , EUC-KR )</td>
              </tr>
            </tbody>
          </table>
        </div>


        <p className="border-top top-line mt-5"></p>
        <div className="p-3">
          <p className="sub-title" id="link-auth-res">
            인증결과수신
          </p>
          <ul>
            <li className="small-bold mb-5">
              <p>결제창을 통해 인증이 완료되면 결과를 가맹점으로 전달 합니다.</p>
              <p>( 페이즘 화면내에서 결제처리로 인해 인증데이터는 REST 통신이 아닌 message 이벤트로 인증데이터를 수신하여야함 )</p>
            </li>

          </ul>


          <table className="table">
            <thead>
              <tr>
                <th scope="col">name</th>
                <th scope="col">description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">resultCode</td>
                <td>결과코드 ( 정상 : 0000, 나머지 : .... ) </td>
              </tr>
              <tr>
                <td className="small-bold">resultMsg</td>
                <td>발급받은 가맹점 mid</td>
              </tr>

              <tr>
                <td className="small-bold">payMethod</td>
                <td>결제방법</td>
              </tr>
              <tr>
                <td className="small-bold">tid</td>
                <td>거래번호</td>
              </tr>

              <tr>
                <td className="small-bold">ediDate</td>
                <td>결제일시</td>
              </tr>

              <tr>
                <td className="small-bold">mid</td>
                <td>상점아이디</td>
              </tr>

              <tr>
                <td className="small-bold">goodsAmt</td>
                <td>거래금액</td>
              </tr>

              <tr>
                <td className="small-bold">mbsReserved</td>
                <td>상점 예약필드 (인증요청시 입력하였던 주문번호 등의 기타예약 필드)</td>
              </tr>

              <tr>
                <td className="small-bold">signData</td>
                <td>결제 정보 암호화 데이터 (승인 요청시 필수)</td>
              </tr>
            </tbody>
          </table>
        </div>



        <p className="border-top top-line mt-5"></p>
        <div className="p-3">
          <p className="sub-title" id="link-req-approval">
            승인요청
          </p>
          

          <table className="table">
            <thead>
              <tr>
                <th scope="col">URL</th>
                <th scope="col">Method</th>
                <th scope="col">Content-Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">https://api.skyclassism.com/payment.do</td>
                <td className="small-bold">POST</td>
                <td className="small-bold">application/x-www-form-urlencoded; charset=utf-8</td>
              </tr>
            </tbody>
          </table>


          <ul>
            <li className="small-bold">
              <p>결제창을 통해 받은 인증 결과로 승인 요청을 보냅니다. 승인 요청 데이터 필드는 아래와 같습니다.</p>   
            </li>
          </ul>

                    
          <table className="table"> 
            <thead>
              <tr>
                <th scope="col">name</th>
                <th scope="col">value</th>
                <th scope="col">description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">tid</td>
                <td>fwenjkfnwkljjefwe....</td>
                <td>거래번호</td>
              </tr>
              <tr>
                <td className="small-bold">ediDate</td>      
                <td>20240102105012</td>
                <td>결제일시</td>
              </tr>
              <tr>
                <td className="small-bold">mid</td>
                <td>paytest01m</td>
                <td>상점 아이디</td>
              </tr>

              <tr>
                <td className="small-bold">goodsAmt</td>
                <td>1000</td>
                <td>거래금액</td>
              </tr>

              <tr>
                <td className="small-bold">charSet</td>
                <td>UTF-8</td>
                <td>인코딩 방식</td>
              </tr>

              <tr>
                <td className="small-bold">encData</td>
                <td>dqwdgsrsdtrehe3gf23erfweq...</td>
                <td>Hash256 암호화 되어진 Hash 거래데이터</td>
              </tr>

              <tr>
                <td className="small-bold">signData</td>
                <td>dqwdqw2121312asfdgdfgdwqrer..</td>
                <td>결제 정보 암호화 데이터</td>
              </tr>


            </tbody>
          </table>

        </div>
    



        <p className="border-top top-line mt-5"></p>
        <div className="p-3">
          <p className="sub-title" id="link-res-approval">
            승인 결과 수신
          </p>
          
          <ul>
            <li className="small-bold">
              <p>승인이 완료되면 결과를 가맹점으로 전달 합니다. 결제 결과 데이터 필드는 아래와 같습니다.</p>   
            </li>
          </ul>

                    
          <table className="table">
            <thead>
              <tr>
                <th scope="col">name</th>
                <th scope="col">description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">resultCd</td>
                <td>결과코드 [0000 : 정상, 기타 : 실패]</td>

              </tr>
              <tr>
                <td className="small-bold">resultMsg</td>
                <td>결과메세지 [성공시 : OK, 실패시 : 기타 오류 메시지]</td>
              </tr>

              <tr>
                <td className="small-bold">payMethod</td>
                <td>지불수단 (결제 방법)</td>
              </tr>
              <tr>
                <td className="small-bold">tid</td>
                <td>거래번호</td>
              </tr>

              <tr>
                <td className="small-bold">appDtm</td>
                <td>결제일시</td>
              </tr>

              <tr>
                <td className="small-bold">appNo</td>
                <td>승인번호</td>
              </tr>

              <tr>
                <td className="small-bold">ordNo</td>
                <td>주문번호</td>
              </tr>

              <tr>
                <td className="small-bold">goodsName</td>
                <td>결제 상품명</td>
              </tr>

              <tr>
                <td className="small-bold">amt</td>
                <td>거래금액</td>
              </tr>

              <tr>
                <td className="small-bold">ordNm</td>
                <td>결제자 이름</td>
              </tr>

              <tr>
                <td className="small-bold">fnNm</td>
                <td>제휴사명</td>
              </tr>

              <tr>
                <td className="small-bold">cancelYN</td>
                <td>취소여부</td>
              </tr>

              <tr>
                <td className="small-bold">appCardCd</td>
                <td>카드 발급사코드</td>
              </tr>

              <tr>
                <td className="small-bold">acqCardCd</td>
                <td>카드 매입사코드</td>
              </tr>
              <tr>
                <td className="small-bold">quota</td>
                <td>카드 할부기간</td>
              </tr>
              <tr>
                <td className="small-bold">usePointAmt</td>
                <td>사용 포인트 양</td>
              </tr>
              <tr>
                <td className="small-bold">cardType</td>
                <td>카드타입</td>
              </tr>
              <tr>
                <td className="small-bold">authType</td>
                <td>인증타입</td>
              </tr>
              <tr>
                <td className="small-bold">mid</td>
                <td>상점아이디</td>
              </tr>
              <tr>
                <td className="small-bold">ediDate</td>
                <td>전문요청일시</td>
              </tr>
              <tr>
                <td className="small-bold">mbsReserved</td>
                <td>상점예약</td>
              </tr>
              <tr>
                <td className="small-bold">charSet</td>
                <td>인코딩방식</td>
              </tr>

            </tbody>
          </table>

          
        </div>


        <p className="border-top top-line mt-5"></p>
        <div className="p-3">
          <p className="sub-title" id="link-example-approval">
            승인코드샘플
          </p>
          <ol>
            <li className="small-bold pt-2">
              승인을 위해 인증데이터를 수신받으려면 결제요청을 보낸 페이지에서
              해당 message 이벤트리스너를 등록하셔야 합니다.
            </li>
            <li className="small-bold pt-2">
              인증결과 수신이후 해당 응답데이터로 곧바로 승인요청을 보냅니다.
            </li>
            <li className="small-bold pt-2">
              action 에서 승인결과를 수신받을 엔드포인트를 지정해주세요.
            </li>
          </ol>

         
        </div>

    
        <div className="p-3 code-display">
          <Approval></Approval>
        </div>






        <p className="border-top top-line mt-5"></p>
        <div className="p-3">
          <p className="sub-title" id="link-cancel-req">
            결제취소요청
          </p>
          

          <table className="table">
            <thead>
              <tr>
                <th scope="col">URL</th>
                <th scope="col">Method</th>
                <th scope="col">Content-Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">https://api.skyclassism.com/payment.cancel</td>
                <td className="small-bold">POST</td>
                <td className="small-bold">application/x-www-form-urlencoded; charset=utf-8</td>
              </tr>
            </tbody>
          </table>


          <ul>
            <li className="small-bold">
              <p>승인처리된 결제에 대해 취소요청을 보냅니다.</p>   
            </li>
          </ul>

                    
          <table className="table">
            <thead>
              <tr>
                <th scope="col">name</th>
                <th scope="col">value</th>
                <th scope="col">description</th>
                <th scope="col">필수여부</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">tid</td>
                <td>fwenjkfnwkljjefwe....</td>
                <td>거래번호</td>
                <td>Y</td>
              </tr>

              <tr>
                <td className="small-bold">ordNo</td>
                <td>123123123123....</td>
                <td>주문번호</td>
                <td></td>
              </tr>
              
              <tr>
                <td className="small-bold">canAmt</td>
                <td>1000</td>
                <td>취소금액</td>
                <td>Y</td>
              </tr>

              <tr>
                <td className="small-bold">canId</td>
                <td>testId</td>
                <td>취소자ID</td>
                <td></td>
              </tr>

              <tr>
                <td className="small-bold">canNm</td>
                <td>홍길동</td>
                <td>취소자명</td>
                <td></td>
              </tr>

              <tr>
                <td className="small-bold">canMsg</td>
                <td>고객요청</td>
                <td>취소사유</td>
                <td>Y</td>
              </tr>

              <tr>
                <td className="small-bold">canIp</td>
                <td>127.0.0.1</td>
                <td>취소 아이피</td>
                <td></td>
              </tr>

              <tr>
                <td className="small-bold">partCanFlg</td>
                <td>0</td>
                <td>부분취소여부 (0:전체취소, 1:부분취소)</td>
                <td>Y</td>
              </tr>

              <tr>
                <td className="small-bold">notiUrl</td>
                <td>https://example.com/noti</td>
                <td>결체 취소 요청을 해서 Back으로 결과를 받을 URL</td>
                <td></td>
              </tr>

              <tr>
                <td className="small-bold">encData</td>
                <td>dqernjeoi1rio2143...</td>
                <td>해시String</td>
                <td>Y</td>
              </tr>

              <tr>
                <td className="small-bold">ediDate</td>
                <td>20210324121212</td>
                <td>전문요청일시 (yyyyMMddHHmmss)</td>
                <td>Y</td>
              </tr>

              <tr>
                <td className="small-bold">goodsSplAmt</td>
                <td>1000</td>
                <td>공급가 (대상: ‘전송금액기준’ 설정업체에 한함)</td>
                <td></td>
              </tr>

              <tr>
                <td className="small-bold">goodsVat</td>
                <td>1000</td>
                <td>부가세 (대상: ‘전송금액기준’ 설정업체에 한함)</td>
                <td></td>
              </tr>

              <tr>
                <td className="small-bold">goodsSvsAmt</td>
                <td>1000</td>
                <td>상품 봉사료 (대상: ‘전송금액기준’ 설정업체에 한함)</td>
                <td></td>
              </tr>

              <tr>
                <td className="small-bold">charset</td>
                <td>UTF-8</td>
                <td>인코딩방식 (UTF-8(default), EUC-KR)</td>
                <td></td>
              </tr>

            </tbody>
          </table>

        </div>


        <p className="border-top top-line mt-5"></p>
        <div className="p-3">
          <p className="sub-title" id="creditcard-receipt">
            신용카드 영수증
          </p>
          
          <ul>
            <li className="small-bold">
              <p>신용카드 결제에 대한 영수증(전표)데이터입니다.</p>   
            </li>
          </ul>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">URL</th>
                <th scope="col">Method</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">https://api.skyclassism.com/receipt.do</td>
                <td className="small-bold">GET</td>
              </tr>
            </tbody>
          </table>

          <div className="p-3 small-bold">※ 필수 파라미터</div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">name</th>
                <th scope="col">value</th>
                <th scope="col">description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">tid</td>
                <td className="small-bold">fwenjkfnwkljjefwe....</td>
                <td className="small-bold">주문번호</td>
              </tr>
            </tbody>
          </table>


          <ul>
            <li className="small-bold" style={{paddingTop: '25px'}}>
              <p>응답되는 영수증 데이터는 아래와 같습니다.</p>   
            </li>
          </ul>

                    
          <table className="table">
            <thead>
              <tr>
                <th scope="col">name</th>
                <th scope="col">value</th>
                <th scope="col">description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="small-bold">CARD_NO</td>
                <td>123456******</td>
                <td>카드번호</td>
              </tr>

              <tr>
                <td className="small-bold">CARD_NO_ENC</td>
                <td>01f9d766....</td>
                <td>카드번호 해싱</td>
              </tr>
              
              <tr>
                <td className="small-bold">REP_NM2</td>
                <td>홍길동</td>
                <td>대표자</td>
              </tr>

              <tr>
                <td className="small-bold">TEL_NO</td>
                <td>01012345678</td>
                <td>사업자 연락처</td>
              </tr>

              <tr>
                <td className="small-bold">PM_NM</td>
                <td>123412*******(삼성)</td>
                <td>카드정보</td>
              </tr>

              <tr>
                <td className="small-bold">GOODS_VAT</td>
                <td>123</td>
                <td>부가세</td>
              </tr>

              <tr>
                <td className="small-bold">QUOTA_MON</td>
                <td>일시불</td>
                <td>일시불 여부</td>
              </tr>

              <tr>
                <td className="small-bold">ORD_NO</td>
                <td>2024041514324866</td>
                <td>가맹점 주문번호</td>
              </tr>

              <tr>
                <td className="small-bold">MBS_TYPE_CD</td>
                <td>1</td>
                <td>직가맹/대행 구분 : 0.자체, 1.대표</td>
              </tr>

              <tr>
                <td className="small-bold">APP_TM</td>
                <td>143307</td>
                <td>승인시각</td>
              </tr>

              <tr>
                <td className="small-bold">ORD_NM</td>
                <td>ssinnario</td>
                <td>구매자명</td>
              </tr>

              <tr>
                <td className="small-bold">GOODS_SVS_AMT</td>
                <td>0</td>
                <td>상품봉사료</td>
              </tr>

              <tr>
                <td className="small-bold">CHKCARD_FLG</td>
                <td>0</td>
                <td>체크카드여부 : 0.일반, 1.체크카드, 2.일반+체크, 3.선불, 4.직불</td>
              </tr>

              <tr>
                <td className="small-bold">ADDR2</td>
                <td>대구시중구길동로 123</td>
                <td>주소</td>
              </tr>

              <tr>
                <td className="small-bold">NOINT_FLG</td>
                <td>0</td>
                <td>무이자할부구분 : 0.일반, 1.무이자</td>
              </tr>

              <tr>
                <td className="small-bold">RCPT_PRT_TYPE</td>
                <td>0</td>
                <td>매출전표 발행여부 : 0.발행, 1.안함, 2.영수증(거래확인서)</td>
              </tr>

              <tr>
                <td className="small-bold">TRX_ST_CD</td>
                <td>1</td>
                <td>카드결제 상태 : 0.승인, 1.전취소, 2.후취소</td>
              </tr>

              <tr>
                <td className="small-bold">GOODS_AMT</td>
                <td>7500</td>
                <td>상품가격</td>
              </tr>

              <tr>
                <td className="small-bold">PAY_NO</td>
                <td>474289******</td>
                <td>결제카드번호</td>
              </tr>

              <tr>
                <td className="small-bold">CO_NM2</td>
                <td>스카이클래스</td>
                <td>보조 사업자</td>
              </tr>

              <tr>
                <td className="small-bold">GOODS_NM</td>
                <td>홍길동 가게</td>
                <td>상품명</td>
              </tr>

              <tr>
                <td className="small-bold">CARD_NM</td>
                <td>삼성</td>
                <td>카드사</td>
              </tr>

              <tr>
                <td className="small-bold">REP_NM</td>
                <td>홍길준</td>
                <td>대표자</td>
              </tr>

              <tr>
                <td className="small-bold">CC_DT</td>
                <td>20240415</td>
                <td>취소일자</td>
              </tr>

              <tr>
                <td className="small-bold">ORD_NM_ENC</td>
                <td>0195b611ee7087cfce88c4da721638a24e</td>
                <td>구매자명 암호화</td>
              </tr>

              <tr>
                <td className="small-bold">CO_NO</td>
                <td>123412345123</td>
                <td>사업자번호</td>
              </tr>

              <tr>
                <td className="small-bold">CO_NO2</td>
                <td>123124534</td>
                <td>보조 사업자번호</td>
              </tr>

              <tr>
                <td className="small-bold">CO_NM</td>
                <td>홍길동 가게</td>
                <td>사업체 상호</td>
              </tr>


              <tr>
                <td className="small-bold">CC_TM</td>
                <td>145004</td>
                <td>취소시각</td>
              </tr>

              <tr>
                <td className="small-bold">PM_CD</td>
                <td>01</td>
                <td>지불수단  : 01.신용카드</td>
              </tr>


              <tr>
                <td className="small-bold">TEL_NO2</td>
                <td>0532670880</td>
                <td>전화번호</td>
              </tr>


              <tr>
                <td className="small-bold">CARD_APP_NO</td>
                <td>58074553</td>
                <td>카드승인번호</td>
              </tr>


              <tr>
                <td className="small-bold">TID</td>
                <td>384440048m01012404151433010971</td>
                <td>주문번호</td>
              </tr>


              <tr>
                <td className="small-bold">GOODS_SPL_AMT</td>
                <td>6818</td>
                <td>상품공급가액</td>
              </tr>


              <tr>
                <td className="small-bold">ADDR</td>
                <td>서울특별시 서초구 동산로7  1층1호</td>
                <td>주소</td>
              </tr>



              <tr>
                <td className="small-bold">STATE_NM</td>
                <td>전취소</td>
                <td>카드결제 상태</td>
              </tr>


              <tr>
                <td className="small-bold">APP_DT</td>
                <td>20240415</td>
                <td>카드승인날짜</td>
              </tr>


              <tr>
                <td className="small-bold">MBS_USR_ID</td>
                <td>kakao_123456</td>
                <td>가맹점고객ID</td>
              </tr>



            </tbody>
          </table>

        </div>



        <Footer></Footer>

        <div
          className="modal fade"
          id="responseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
          ref={modalRef}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body" style={modalStyle1}>
                <iframe
                  id="responseIframe"
                  name="responseIframe"
                  style={modalStyle2}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Main;
